import createClient from '@sanity/client';
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'
const token = process.env.REACT_APP_SANITY_SECRET_TOKEN;
const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
if (!token || !projectId) {
  throw new Error(
    'A secret is provided but there is no `SANITY_API_READ_TOKEN` environment variable setup.'
  );
}
export const client = createClient({
  projectId: projectId,
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2022-02-01', // use current date (YYYY-MM-DD) to target the latest API version
  token: token
});
