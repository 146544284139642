import { gql } from '@apollo/client';

export const GET_SKILLS = gql`
  query {
    allSkills {
      _id
      name
      icon {
        asset {
          _id
          url
        }
      }
    }
  }
`;

export const GET_WORK_EXPERIENCES = gql`
  query {
    allWorkExperience {
      _id
      year
      jobTitle
      company
    }
  }
`;

export const GET_ABOUT = gql`
  query {
    allAbout {
      _id
      title
      description
      imgUrl {
        asset {
          url
        }
      }
    }
  }
`;
export const GET_PROJECTS = gql`
  query {
    allProjects {
      _id
      title
      description
      isLinkAvailable
      projectLink
      codeLink
      image {
        asset {
          url
        }
      }
      tags
    }
  }
`;

export const GET_INTRO = gql`
  query {
    allIntroduction {
      _id
      title
      description
    }
  }
`;
