import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import React from 'react';
import { GET_INTRO } from '../../apollo/queries';
import { images } from '../../constants';
import { AppWrap } from '../../wrapper';
import './Header.scss';
const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
};

function Header() {
  const { data, loading } = useQuery(GET_INTRO);
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span> 👋 </span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello there! I am</p>
              <h1 className="head-text">Zied</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            {loading ? (
              <div className="lds-ripple">
                <div>
                  <div> </div>
                </div>
              </div>
            ) : (
              data?.allIntroduction?.map((intro, i) => (
                <p key={intro?._id + i} className="p-text">
                  {intro?.description}
                </p>
              ))
            )}
            {loading ? <p className="p-text"></p> : <span> 😂 </span>}
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile" />

        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[
          images.mongodb,
          images.graphql,
          images.node,
          images.trpc,
          images.react
        ].map((stackLogs, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={stackLogs} alt="profile_bg" />
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default AppWrap(Header, 'home');
