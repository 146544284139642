import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import React from 'react';
import { GET_SKILLS } from '../../apollo/queries';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Skills.scss';
const Skills = () => {
  const { data, loading } = useQuery(GET_SKILLS);

  // const {data:{allWorkExperience}} = useQuery(GET_WORK_EXPERIENCES);

  return (
    <>
      <h2 className="head-text">
        Skills <span>&</span> Experi<span>ences</span>
      </h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {loading ? (
            <div className="lds-ripple">
              <div>
                <div> </div>
              </div>
            </div>
          ) : (
            data?.allSkills.map((skill, i) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-item app__flex"
                key={skill?._id}
              >
                <div className="app__flex">
                  <img src={skill?.icon?.asset?.url} alt={skill?.name} />
                </div>
                <p className="p-text">{skill.name}</p>
              </motion.div>
            ))
          )}
        </motion.div>
        {/* <div className="app__skills-exp">
          {allWorkExperience.map((experience) => (
            <motion.div className="app__skills-exp-item" key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
               
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={experience.jobTitle}
                      key={experience._id}
                    >
                      <h4 className="bold-text">{experience.jobTitle}</h4>
                      <p className="p-text">{experience.company}</p>
                    </motion.div>
              </motion.div>
            </motion.div>
          ))}
        </div>  */}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg'
);
