import api from '../assets/api.png';
import email from '../assets/email.png';
import graphql from '../assets/graphql.png';
import mongodb from '../assets/mongodb.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';

import circle from '../assets/circle.svg';
import logo from '../assets/logo.svg';
import pp from '../assets/pp.png';
import profile from '../assets/profile.png';

import trpc from '../assets/trpc.svg';

import profile2 from '../assets/profile-AI2.png';

const images = {
  email,
  profile2,
  profile,
  api,
  graphql,
  node,
  react,
  mongodb,
  redux,
  pp,
  circle,
  logo,
  trpc
};

export default images;
