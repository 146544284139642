import { ApolloProvider } from "@apollo/client";
import React from "react";
import { apolloClient } from "./apollo/apolloClient";
import "./App.scss";
import { Navbar } from "./components";
import { About, Footer, Header, Skills, Work } from "./container";

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <div className="app">
        <Navbar />
        <Header />
        <About />
        <Work />
        <Skills />
        {/* <Testimonial /> */}
        {/* < /> */}
        <Footer />
      </div>
    </ApolloProvider>
  );
};

export default App;
