import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import React from 'react';
import { GET_ABOUT } from '../../apollo/queries';
import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
function About() {
  const { data, loading } = useQuery(GET_ABOUT);

  return (
    <>
      <h2 className="head-text">
        I Know That <span>Good Development </span> <br /> means{' '}
        <span>Less Headache </span>
      </h2>
      <div className="app__profiles">
        {loading ? (
          <div className="lds-ripple">
            <div>
              <div> </div>
            </div>
          </div>
        ) : (
          data?.allAbout.map((about, i) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className="app__profile-item"
              key={about?._id + i}
            >
              <img src={about?.imgUrl?.asset?.url} alt={about?.title} />
              <h2 className="bold-text" style={{ marginTop: 20 }}>
                {about?.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about?.description}
              </p>
            </motion.div>
          ))
        )}
      </div>
    </>
  );
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg'
);
