import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
const Socials = [
  { link: 'https://www.linkedin.com/in/zee-xiyad', icon: <BsLinkedin /> },
  { link: 'https://github.com/Mo-Xiyad', icon: <FaGithub /> }
  // { link: "https://www.instagram.com/ziii_ed", icon: <BsInstagram /> },
  // { link: "", icon: <FaFacebookF /> },
];

const SocialMedia = () => (
  <div className="app__social">
    {Socials.map((social, i) => (
      <a
        key={i + social.link}
        href={social.link}
        target="_blank"
        rel="noreferrer"
      >
        <div>{social.icon}</div>
      </a>
    ))}
    {/* <div>
      <BsLinkedin />
    </div>
    <div>
      <FaGithub />
    </div>
    <div>
      <FaFacebookF />
    </div>
    <div>
      <BsInstagram />
    </div> */}
  </div>
);

export default SocialMedia;
