import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { GET_PROJECTS } from '../../apollo/queries';

import { AppWrap, MotionWrap } from '../../wrapper';

import './Projects.scss';

function Projects() {
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterWork, setFilterWork] = useState([]);
  const { data, loading } = useQuery(GET_PROJECTS);

  useEffect(() => {
    setFilterWork(data?.allProjects);
  }, [data]);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWork(data?.allProjects);
      } else {
        setFilterWork(
          data?.allProjects.filter((work) => work.tags.includes(item))
        );
      }
    }, 500);
  };

  return (
    <>
      {filterWork?.map((project, i) => (
        <React.Fragment key={i}>
          <input
            type="checkbox"
            id={`my-modal-${project?._id}`}
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box relative bg-[#eef5f8]">
              <label
                htmlFor={`my-modal-${project?._id}`}
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-slate-600">
                {project?.title}
              </h3>
              <p className="py-4 text-slate-500">{project?.description}</p>
            </div>
          </div>
        </React.Fragment>
      ))}
      <h2 className="head-text">
        My Creative <span>Portfolio</span> Section
      </h2>
      <p>
        I am a developer, not a designer. Please don't judge my design skills as
        it is not my area of expertise.
      </p>
      <div className="app__work-filter">
        {['React.js', 'Express.js', 'Node.js', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text
              ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {loading ? (
          <div className="lds-ripple">
            <div>
              <div> </div>
            </div>
          </div>
        ) : (
          filterWork?.map((project) => (
            <div className="app__work-item app__flex" key={project?._id}>
              <div className="app__work-img app__flex">
                <img src={project?.image?.asset?.url} alt={project?.title} />

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  transition={{
                    duration: 0.25,
                    ease: 'easeInOut',
                    staggerChildren: 0.5
                  }}
                  className="app__work-hover app__flex"
                >
                  {project?.isLinkAvailable && (
                    <a
                      href={project?.projectLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="app__flex"
                      >
                        <AiFillEye />
                      </motion.div>
                    </a>
                  )}
                  {project?.codeLink && (
                    <a
                      href={project?.codeLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="app__flex"
                      >
                        <AiFillGithub />
                      </motion.div>
                    </a>
                  )}
                </motion.div>
              </div>

              <div className="app__work-content app__flex">
                <h4 className="bold-text">{project?.title}</h4>
                <p className="p-text" style={{ marginTop: 10 }}>
                  {shortenText(project?.description, 0, 100)} {'  '}
                </p>
                {project?.description && project?.description.length > 100 && (
                  <label
                    htmlFor={`my-modal-${project?._id}`}
                    className="btn btn-outline btn-xs"
                  >
                    more
                  </label>
                )}

                <div className="app__work-tag app__flex">
                  <p className="p-text">{project?.tags[0]}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </motion.div>
    </>
  );
}

export default AppWrap(
  MotionWrap(Projects, 'app__works'),
  'work',
  'app__primarybg'
);
const shortenText = (text, startingPoint, maxLength) => {
  return text.length > maxLength
    ? text.slice(startingPoint, maxLength) + ' . . .'
    : text;
};
